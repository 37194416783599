/* ResumeTransform.module.css */
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;600;700&display=swap');

.resumeTransform {
  color: #f5f5f7;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  padding: 60px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  
}

.container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.6),
    0 0 0 1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  position: relative;
  height: 77vh;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contentWrapper::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background-color: #000;
  border-radius: 32px;
  z-index: -1;
  opacity: 0.5;
  filter: blur(15px);
}

.contentWrapper::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 30px;
  padding: 1px;
 
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.textContent, .visualContent {
  padding: 40px;
}

.mainTitle {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
}

.highlight {
  color: #2997ff;
  position: relative;
  display: inline-block;
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2997ff;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-out;
}

.resumeTransform.visible .highlight::after {
  transform: scaleX(1);
}

.description {
  font-size: 1rem;
  color: #a1a1a6;
  line-height: 1.6;
  margin-bottom: 30px;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.feature {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #f5f5f7;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.feature::before {
  content: '✓';
  color: #2997ff;
  margin-right: 15px;
  font-weight: bold;
}

.ctaButton {
  display: inline-block;
  padding: 12px 24px;
  background-color: #2997ff;
  color: #000;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 30px;
  transition: all 0.3s ease;
}

.ctaButton:hover {
  background-color: #0071e3;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(41, 151, 255, 0.4);
}

.resumeComparison {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.resume {
  flex: 1 1 300px;
  border-radius: 20px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.resumeImageContainer {
  position: relative;
 
  padding-top: 147.42%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 
    10px 10px 30px rgba(0, 0, 0, 0.3),
    -10px -10px 30px rgba(255, 255, 255, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.resumeImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 50vh;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.resume:hover .resumeImageContainer {
  transform: translateY(-5px);
  box-shadow: 
    15px 15px 40px rgba(0, 0, 0, 0.4),
    -15px -15px 40px rgba(255, 255, 255, 0.1);
}

.resumeHeader {
  font-size: 1.8rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 15px;
  text-align: center;
}

.transformArrow {
  font-size: 2.5rem;
  color: #2997ff;
  animation: pulse 1.5s infinite;
  text-align: center;
  width: 100%;
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

@media (min-width: 768px) {
  .contentWrapper {
    flex-direction: row;
  }

  .textContent, .visualContent {
    flex: 1;
  }

  .mainTitle {
    font-size: 3rem;
  }

  .resumeComparison {
    flex-wrap: nowrap;
  }

  .transformArrow {
    width: auto;
  }
}

@media (min-width: 1024px) {
  .contentWrapper {
    margin-top: 100px;
  }
  .mainTitle {
    font-size: 3.5rem;
  }

  .description {
    font-size: 1.1rem;
  }

  .feature {
    font-size: 1.1rem;
  }

  .ctaButton {
    padding: 15px 30px;
    font-size: 1.1rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .resumeTransform * {
    animation: none !important;
    transition: none !important;
  }
}

@media (max-width: 767px) {
  .resumeTransform {
    padding: 40px 0;
  }

  .contentWrapper {
    height: auto;
    flex-direction: column;
  }

  .textContent, .visualContent {
    padding: 30px;
  }

  .mainTitle {
    font-size: 2rem;
    text-align: center;
  }

  .description {
    font-size: 0.9rem;
    text-align: center;
  }

  .features {
    align-items: center;
  }

  .feature {
    font-size: 0.9rem;
  }

  .ctaWrapper {
    text-align: center;
  }

  .ctaButton {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .resumeComparison {
    flex-direction: column;
    gap: 30px;
  }

  .resume {
    flex: 1 1 auto;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .resumeHeader {
    font-size: 1.5rem;
  }

  .resumeImageContainer {
    padding-top: 141.42%; /* Adjust aspect ratio for mobile */
  }

  .resumeImage {
    width: 100%;
    height: 100%;
  }
}