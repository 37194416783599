.job-listings {
  --primary-color: #6C63FF;
  --secondary-color: #FF6584;
  --background-color: #F0F4F8;
  --card-background: #FFFFFF;
  --text-color: #333333;
  --text-light: #666666;
  --border-radius: 12px;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;

  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--background-color);
  font-family: 'Roboto', sans-serif;
}

.job-listings__title {
  font-size: 2.5rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.job-listings__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.job-listings__search-bar {
  display: flex;
  width: 100%;
  max-width: 600px;
  position: relative;
}

.job-listings__search-input {
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  border: none;
  border-radius: 50px;
  background-color: var(--card-background);
  box-shadow: var(--box-shadow);
  font-size: 1rem;
  transition: var(--transition);
}

.job-listings__search-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--primary-color);
}

.job-listings__search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  color: var(--primary-color);
  font-size: 1.2rem;
}

.job-listings__filter-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: var(--transition);
  margin-left: 1rem;
}

.job-listings__filter-btn:hover {
  background-color: #5a52d5;
  transform: translateY(-2px);
}

.job-listings__filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  max-width: 600px;
}

.job-listings__filters select,
.job-listings__filters label {
  flex: 1;
  min-width: 150px;
  padding: 0.5rem;
  border-radius: 25px;
  border: 1px solid var(--primary-color);
  background-color: white;
  font-size: 0.9rem;
}

.job-listings__filters label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
}

.job-listings__sort {
  position: relative;
  width: 100%;
  max-width: 200px;
}

.job-listings__sort-select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 25px;
  background-color: var(--card-background);
  box-shadow: var(--box-shadow);
  font-size: 1rem;
  appearance: none;
  cursor: pointer;
}

.job-listings__sort-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--primary-color);
}

.job-listings__results {
  text-align: center;
  color: var(--text-light);
  margin-bottom: 2rem;
  font-size: 1.1rem;
  font-style: italic;
}

.job-listings__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.job-card {
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  transition: var(--transition);
  display: flex;
  flex-direction: column;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.job-card__header {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.job-card__logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.job-card__title-container {
  flex: 1;
}

.job-card__title {
  font-size: 1.4rem;
  color: var(--primary-color);
  margin: 0 0 0.25rem 0;
}

.job-card__company {
  font-size: 1rem;
  color: var(--text-color);
  margin: 0 0 0.5rem 0;
}

.job-card__location {
  display: flex;
  align-items: center;
  color: var(--text-light);
  font-size: 0.9rem;
}

.job-card__location svg {
  margin-right: 0.5rem;
}

.job-card__actions {
  display: flex;
  gap: 0.5rem;
}

.job-card__action-btn {
  background: none;
  border: none;
  color: var(--text-light);
  font-size: 1.2rem;
  cursor: pointer;
  transition: var(--transition);
}

.job-card__action-btn:hover {
  color: var(--primary-color);
}

.job-card__skills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.job-card__skill {
  background-color: #f0f0f0;
  color: var(--text-color);
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: var(--transition);
}

.job-card__skill:hover {
  background-color: var(--primary-color);
  color: white;
}

.job-card__details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem 1.5rem;
  flex-grow: 1;
}

.job-card__detail {
  display: flex;
  align-items: center;
}

.job-card__detail svg {
  color: var(--primary-color);
  margin-right: 0.5rem;
}

.job-card__detail-label {
  color: var(--text-light);
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.job-card__detail-value {
  font-weight: bold;
  color: var(--text-color);
}

.job-card__footer {
  background-color: var(--secondary-color);
  color: white;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.job-card__posted {
  font-size: 0.9rem;
}

.job-card__tags {
  display: flex;
  gap: 0.5rem;
}

.job-card__tag {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.job-card__tag--remote {
  background-color: #4CAF50;
}

.job-card__tag--featured {
  background-color: #FFC107;
  color: #333;
}

.job-card__apply-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: var(--transition);
}

.job-card__apply-btn:hover {
  background-color: #5a52d5;
  transform: translateY(-2px);
}

.job-listings__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.job-listings__popup-content {
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  position: relative;
}

.popup-close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-light);
}

.popup-header h3 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.popup-body {
  margin-top: 1rem;
}

.popup-highlights {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
}

.popup-highlights li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.popup-highlights li:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateX(5px);
}

.popup-highlights li svg {
  color: var(--primary-color);
  margin-right: 1rem;
  font-size: 1.2rem;
}

.job-listings__popup-btn {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 25px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  width: 100%;
  margin-top: 2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.job-listings__popup-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .job-listings {
    padding: 2rem;
  }

  .job-listings__title {
    font-size: 2.5rem;
  }

  .job-listings__header {
    flex-direction: column;
  }

  .job-listings__search-bar,
  .job-listings__sort {
    width: 100%;
  }

  .job-listings__filter-btn {
    margin-left: 0;
    margin-top: 1rem;
  }

  .job-card__header {
    flex-wrap: wrap;
  }

  .job-listings__grid {
    grid-template-columns: 1fr;
  }

  .job-card__details {
    grid-template-columns: 1fr;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.job-card {
  animation: fadeIn 0.5s ease-out;
}

.job-listings__loading,
.job-listings__error {
  text-align: center;
  font-size: 1.5rem;
  color: var(--text-color);
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.job-listings__loading::after {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border: 3px solid var(--text-color);
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Custom scrollbar for webkit browsers */
.job-listings::-webkit-scrollbar {
  width: 10px;
}

.job-listings::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.job-listings::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 5px;
}

.job-listings::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}