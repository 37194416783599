/* src/components/CourseStructure.module.css */
.courseStructure {
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  padding: 80px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* src/components/CourseStructure.module.css */

.title {
    font-size: 60px;
    font-weight: 900;
    line-height: 1.1;
    margin-bottom: 20px;
    text-align: center;
    background: linear-gradient(135deg, #2997ff, #5ac8fa, #ff2d55);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    position: relative;
    display: inline-block;
  }
  
  .title::after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(135deg, #2997ff, #5ac8fa, #ff2d55);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    filter: blur(10px);
    opacity: 0.5;
  }
  
  .subtitle {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 60px;
    color: #86868b;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.4;
    letter-spacing: 0.2px;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 40px;
    }
  
    .subtitle {
      font-size: 20px;
    }
  }

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
}

.textContent {
  flex: 1;
 
  border-radius: 30px;
  padding: 30px;
  
}

.monthSection {
  background: rgb(0, 0, 0);
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23),
    inset 0 -5px 10px rgba(255, 255, 255, 0.05),
    inset 0 5px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.monthSection::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 80%);
  transform: rotate(45deg);
  pointer-events: none;
}

.monthSection:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 
    0 15px 30px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22),
    inset 0 -5px 10px rgba(255, 255, 255, 0.05),
    inset 0 5px 10px rgba(0, 0, 0, 0.2);
}

.monthTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #2997ff;
}

.featureList {
  list-style-type: none;
  padding: 0;
}

.featureItem {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
}

.checkmark {
  color: #32d74b;
  margin-right: 10px;
  font-size: 20px;
}

.imageContent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.image:hover {
  transform: scale(1.05);
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;
  }
  
  .imageContent {
    order: -1;
    margin-bottom: 40px;
  }
}