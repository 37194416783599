/* Add these new styles to your existing CSS file */

@keyframes confettiRain {
  0% { transform: translateY(-100vh) rotateZ(0deg); }
  100% { transform: translateY(100vh) rotateZ(360deg); }
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.popupOverlay.visible {
  opacity: 1;
  visibility: visible;
}

.popup {
  background: linear-gradient(135deg, #6dd5ed, #2193b0);
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transform: scale(0.8);
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.popupOverlay.visible .popup {
  transform: scale(1);
}

.popupTitle {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.popupMessage {
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 2rem;
}

.popupClose {
  background-color: #ffffff;
  color: #2193b0;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.popupClose:hover {
  background-color: #2193b0;
  color: #ffffff;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #f0f0f0;
  opacity: 0.7;
  animation: confettiRain 5s linear infinite;
}

.confetti:nth-child(2n) {
  width: 5px;
  height: 5px;
  animation-duration: 4s;
  background-color: #ffeb3b;
}

.confetti:nth-child(3n) {
  width: 8px;
  height: 8px;
  animation-duration: 6s;
  background-color: #ff5722;
}

.confetti:nth-child(4n) {
  width: 7px;
  height: 7px;
  animation-duration: 5s;
  background-color: #4caf50;
}
.contactContainer {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.contactContainer::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
  transform: rotate(30deg);
  
}

.mapContainer {
  margin-bottom: 2rem;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}

.formContainer {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

.title {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.title::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #3498db;
  margin: 0.5rem auto;
}

.subtitle {
  font-size: 1.2rem;
  color: #7f8c8d;
  margin-bottom: 2rem;
  text-align: center;
  font-style: italic;
}

.form {
  display: grid;
  gap: 1.5rem;
}

.formGroup {
  position: relative;
}

.input,
.textarea {
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.8);
}

.input:focus,
.textarea:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.textarea {
  min-height: 120px;
  resize: vertical;
}

.input::placeholder,
.textarea::placeholder {
  color: #bdc3c7;
}

.label {
  position: absolute;
  top: -0.5rem;
  left: 0.8rem;
  background-color: white;
  padding: 0 0.3rem;
  font-size: 0.8rem;
  color: #7f8c8d;
  transition: all 0.3s ease;
}

.input:focus ~ .label,
.input:not(:placeholder-shown) ~ .label,
.textarea:focus ~ .label,
.textarea:not(:placeholder-shown) ~ .label {
  top: -0.8rem;
  font-size: 0.7rem;
  color: #3498db;
}

.submitBtn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1rem;
  font-size: 1.1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.submitBtn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
}

.submitBtn:hover::before {
  width: 300px;
  height: 300px;
}

.submitBtn:hover {
  background-color: #2980b9;
}

.errorMessage,
.successMessage {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.errorMessage {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ef9a9a;
}

.successContent {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #a5d6a7;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  margin-top: 1rem;
}

.errorIcon,
.successIcon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.visible {
  animation: fadeIn 0.5s ease-out;
}

@media (max-width: 768px) {
  .contactContainer {
    padding: 1rem;
  }

  .formContainer {
    padding: 1.5rem;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .form {
    gap: 1rem;
  }
}

/* Fancy input animations */
@keyframes inputFocus {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

.input:focus,
.textarea:focus {
  animation: inputFocus 0.3s ease-in-out;
}

/* Pulsating submit button */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7); }
  70% { box-shadow: 0 0 0 10px rgba(52, 152, 219, 0); }
  100% { box-shadow: 0 0 0 0 rgba(52, 152, 219, 0); }
}

.submitBtn {
  animation: pulse 2s infinite;
}

/* Floating labels */
.label {
  transition: all 0.3s ease;
}

.input:focus ~ .label,
.textarea:focus ~ .label,
.input:not(:placeholder-shown) ~ .label,
.textarea:not(:placeholder-shown) ~ .label {
  transform: translateY(-1.5rem) scale(0.8);
  color: #3498db;
}

/* Fancy checkbox styles */
.checkbox {
  display: none;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkboxCustom {
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #3498db;
  border-radius: 4px;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.checkbox:checked + .checkboxLabel .checkboxCustom {
  background-color: #3498db;
}

.checkbox:checked + .checkboxLabel .checkboxCustom::after {
  content: '✓';
  color: white;
  font-size: 0.8rem;
}

/* Fancy radio button styles */
.radio {
  display: none;
}

.radioLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radioCustom {
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #3498db;
  border-radius: 50%;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.radio:checked + .radioLabel .radioCustom::after {
  content: '';
  width: 0.6rem;
  height: 0.6rem;
  background-color: #3498db;
  border-radius: 50%;
}

/* Ensure styles don't leak to other components */
:global(.otherComponent) .contactContainer {
  all: initial;
}
/* Add these new styles to your existing CSS file */

.contactInfoContainer {
  background: linear-gradient(135deg, #252a2b, #2b2f30);
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.contactInfoContainer::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
  transform: rotate(30deg);
}

.contactInfoTitle {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.contactInfoList {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
}

.contactInfoItem {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  transition: all 0.3s ease;
}

.contactInfoItem:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.contactInfoIcon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.floatingIcon {
  animation: float 3s ease-in-out infinite;
}

@media (max-width: 768px) {
  .contactInfoList {
    flex-direction: column;
    align-items: center;
  }
}