/* HeroSection.module.css */
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;600;700&display=swap');

.heroWrapper {
  
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;   
  margin-top: -40px;
}

.heroSection {
  background-color: #111;
  color: #fff;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1350px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4), 0 0 0 1px rgba(255, 255, 255, 0.1);
  overflow: hidden;
  position: relative;
}

.heroSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
}

.container {
  max-width: 500px;
}

.title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  letter-spacing: -0.02em;
  background: linear-gradient(90deg, #fff, #a1a1a6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bulletPoints {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
}

.bulletPoints li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: #f0f0f0;
}

.bulletPoints li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #1E90FF; /* Dodger Blue, matching the footer accent color */
}

.subtitle {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 2rem;
  color: #a1a1a6;
}

.ctaContainer {
  display: flex;
  gap: 1rem;
}

.ctaPrimary, .ctaSecondary {
  padding: 0.8rem 1.5rem;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.ctaPrimary {
  background-color: #0071e3;
  color: #fff;
}

.ctaPrimary:hover {
  background-color: #0077ed;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 113, 227, 0.3);
}

.ctaSecondary {
  background-color: rgba(255, 255, 255, 0.1);
  color: #2997ff;
}

.ctaSecondary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.heroImage {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.3));
}

@media (max-width: 1024px) {
  .heroSection {
    flex-direction: column;
    text-align: center;
    padding: 3rem 2rem;
  }

  .container {
    margin-bottom: 2rem;
  }

  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .ctaContainer {
    justify-content: center;
  }

  .imageContainer {
    margin-left: 0;
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .heroWrapper {
    max-width: 100%;
    padding: 2rem 1rem;
  }

  .heroSection {
    padding: 2rem 1.5rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1rem;
  }
}