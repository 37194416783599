.exploreCourses {
    background: linear-gradient(135deg, #000000, #1a1a1a);
    padding: 100px 0;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  .exploreCourses.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .exploreCourses::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0) 70%);
    transform: rotate(45deg);
    z-index: 1;
    animation: pulse 10s infinite;
  }
  
  @keyframes pulse {
    0%, 100% { opacity: 0.5; }
    50% { opacity: 1; }
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    z-index: 2;
  }
  
  .title {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 60px;
    background: linear-gradient(90deg, #fff, #a0a0a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 20px rgba(255,255,255,0.1);
  }
  
  .highlight {
    color: #2997ff;
    -webkit-text-fill-color: #2997ff;
  }
  
  .courseGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
  }
  
  .courseCard {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 30px;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.1);
    transform: translateY(0);
  }
  
  .courseCard::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, var(--course-color) 0%, rgba(255,255,255,0) 70%);
    opacity: 0.1;
    transform: rotate(45deg);
    transition: all 0.5s ease;
  }
  
  .courseCard.hovered {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0 20px 30px rgba(0,0,0,0.2);
  }
  
  .courseCard.hovered::before {
    transform: rotate(45deg) translate(50%, 50%);
    opacity: 0.2;
  }
  
  .courseIcon {
    font-size: 48px;
    margin-bottom: 20px;
    text-shadow: 0 0 10px var(--course-color);
  }
  
  .courseTitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--course-color);
  }
  
  .courseDescription {
    font-size: 16px;
    margin-bottom: 20px;
    flex-grow: 1;
    color: rgba(255,255,255,0.8);
  }
  
  .courseMeta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .duration, .price {
    font-size: 18px;
    font-weight: 500;
    color: var(--course-color);
  }
  
  .chartContainer {
    margin-bottom: 20px;
    transition: all 0.3s ease;
  }
  
  .hovered .chartContainer {
    transform: scale(1.05);
  }
  
  .featureList {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .featureItem {
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
    color: rgba(255,255,255,0.8);
  }
  
  .featureItem::before {
    content: '✓';
    color: var(--course-color);
    position: absolute;
    left: 0;
  }
  
  .applyButton {
    background-color: var(--course-color);
    color: #ffffff;
    padding: 12px 24px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: 600;
    text-align: center;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .applyButton::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent);
    transition: all 0.5s;
  }
  
  .applyButton:hover::before {
    left: 100%;
  }
  
  .buttonGlow {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    transform: scale(0);
    transition: transform 0.5s ease;
  }
  
  .applyButton:hover .buttonGlow {
    transform: scale(1);
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 36px;
    }
  
    .courseCard {
      padding: 20px;
    }
  
    .courseTitle {
      font-size: 20px;
    }
  
    .courseDescription, .duration, .price {
      font-size: 14px;
    }
  
    .featureItem {
      font-size: 12px;
    }
  
    .applyButton {
      padding: 10px 20px;
      font-size: 14px;
    }
  }