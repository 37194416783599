@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;600&display=swap');

.apple-style-wrapper {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  
  color: #f5f5f7;
  padding: 50px 20px;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
 
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.apple-style-wrapper::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
 
  z-index: -1;
  filter: blur(20px);
  opacity: 0.5;
  border-radius: 35px;
}

.apple-content-container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 2;
}



.apple-main-heading {
  font-size: clamp(36px, 5vw, 64px);
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 40px;
  text-align: center;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease, transform 0.8s ease;
  background: linear-gradient(90deg, #2997ff, #5ac8fa, #ff2d55, #2997ff);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 6s ease infinite;
}

.apple-flex-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}

.apple-image-wrapper {
  flex: 1 1 300px;
  opacity: 0;
  transform: translateX(-30px) rotate(-5deg);
  transition: opacity 1s ease, transform 1s ease;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  box-shadow: 0 20px 40px rgba(0,0,0,0.4);
  max-width: 50%;
}

.apple-feature-img {
  width: 200%;
  height: auto;
  object-fit: cover;
  object-position: left;
  transition: transform 0.5s ease;
}

.apple-image-wrapper:hover .apple-feature-img {
  transform: scale(1.05);
}

.apple-platform-container {
  flex: 1 1 300px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.apple-platform-item {
  
  border-radius: 20px;
  padding: 15px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px) rotate(5deg);
  overflow: hidden;
  position: relative;
}

.apple-platform-item::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  
  transform: rotate(45deg);
  transition: all 0.5s ease;
}

.apple-platform-item:hover::before {
  transform: rotate(45deg) translate(50%, 50%);
}

.apple-platform-item:hover {
  transform: translateY(-5px) scale(1.05) rotate(0deg);
  
}

.apple-platform-logo {
  font-size: clamp(24px, 4vw, 36px);
  font-weight: 700;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;
}

.apple-platform-item:hover .apple-platform-logo {
  transform: rotate(360deg);
}

.apple-platform-name {
  font-size: clamp(16px, 3vw, 24px);
  font-weight: 500;
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;
}

.apple-platform-item:hover .apple-platform-name {
  transform: scale(1.1);
}



.apple-style-wrapper.apple-animate .apple-main-heading {
  opacity: 1;
  transform: translateY(0);
}

.apple-style-wrapper.apple-animate .apple-image-wrapper {
  opacity: 1;
  transform: translateX(0) rotate(0deg);
  transition-delay: 0.2s;
}

.apple-style-wrapper.apple-animate .apple-platform-item {
  opacity: 1;
  transform: translateY(0) rotate(0deg);
}

.apple-style-wrapper.apple-animate .apple-platform-item:nth-child(1) { transition-delay: 0.4s; }
.apple-style-wrapper.apple-animate .apple-platform-item:nth-child(2) { transition-delay: 0.6s; }
.apple-style-wrapper.apple-animate .apple-platform-item:nth-child(3) { transition-delay: 0.8s; }
.apple-style-wrapper.apple-animate .apple-platform-item:nth-child(4) { transition-delay: 1s; }

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.apple-style-wrapper.apple-animate .apple-platform-item {
  animation: float 3s ease-in-out infinite;
}

.apple-style-wrapper.apple-animate .apple-platform-item:nth-child(1) { animation-delay: 0s; }
.apple-style-wrapper.apple-animate .apple-platform-item:nth-child(2) { animation-delay: 0.5s; }
.apple-style-wrapper.apple-animate .apple-platform-item:nth-child(3) { animation-delay: 1s; }
.apple-style-wrapper.apple-animate .apple-platform-item:nth-child(4) { animation-delay: 1.5s; }

@media (max-width: 768px) {
  .apple-style-wrapper {
    padding: 0px 0px;
    min-height: 100vh;
  }

  .apple-flex-layout {
    flex-direction: column;
  }
  
  .apple-image-wrapper {
    margin-bottom: 30px;
    max-width: 100%;
  }

  .apple-feature-img {
    width: 100%;
    margin-top: 0;
  }

  .apple-platform-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .apple-platform-container {
    grid-template-columns: 1fr;
  }

  .apple-platform-item {
    padding: 10px;
  }

  .apple-platform-logo {
    width: 40px;
    height: 40px;
  }
}
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@400;600;700&display=swap');

.apple-features-section,
.apple-community-section {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.apple-features-section {
  background: linear-gradient(to bottom, #000 0%, #1a1a1a 100%);
}

.apple-feature-item {
  transition: all 0.3s ease;
}

.apple-feature-item:hover {
  transform: translateY(-5px);
}

.apple-feature-item img {
  transition: all 0.3s ease;
}

.apple-feature-item:hover img {
  transform: scale(1.05);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.apple-community-section {
  background: linear-gradient(to bottom, #1a1a1a 0%, #2a2a2a 100%);
}

.apple-community-section a {
  transition: all 0.3s ease;
}

.apple-community-section a:hover {
  transform: translateX(5px);
}