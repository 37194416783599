/* FAQPage.css */

.faq-page {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    min-height: 100vh;
    padding: 50px 20px;
    box-sizing: border-box;
  }
  
  .faq-header {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .faq-header h1 {
    font-size: 3rem;
    color: #2c3e50;
    margin-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  }
  
  .faq-header p {
    font-size: 1.2rem;
    color: #34495e;
  }
  
  .faq-list {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    margin-bottom: 20px;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .faq-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.15);
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
  }
  
  .faq-question h3 {
    font-size: 1.2rem;
    color: #2c3e50;
    margin: 0;
  }
  
  .faq-icon {
    font-size: 1.5rem;
    color: #3498db;
    transition: transform 0.3s ease;
  }
  
  .faq-item.open .faq-icon {
    transform: rotate(45deg);
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
  }
  
  .faq-item.open .faq-answer {
    max-height: 1000px;
    padding: 0 20px 20px;
  }
  
  .faq-answer p {
    color: #34495e;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .faq-header h1 {
      font-size: 2.5rem;
    }
  
    .faq-header p {
      font-size: 1rem;
    }
  
    .faq-question h3 {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .faq-header h1 {
      font-size: 2rem;
    }
  
    .faq-item {
      border-radius: 10px;
    }
  
    .faq-question {
      padding: 15px;
    }
  
    .faq-item.open .faq-answer {
      padding: 0 15px 15px;
    }
  }