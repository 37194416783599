@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;600;700&display=swap');

.feature-showcase {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  padding: 4rem 0;
  background-color: #f5f5f7;
  overflow: hidden;
  
}
.feature-card:first-child {
    margin-left: 10rem;
  }
  

.feature-showcase__title {
  font-size: 5rem;
  font-weight: 700;
  text-align: left;
  margin-bottom: 2rem;
  color: #1d1d1f;
  padding: 0 2rem;
  letter-spacing: -0.03em;
}

.feature-showcase__scroll {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 2rem;
  gap: 1.5rem;
}

.feature-showcase__scroll::-webkit-scrollbar {
  display: none;
}

.feature-card {
  flex: 0 0 auto;
  width: 360px;
  height: 600px;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  scroll-snap-align: start;
  transition: all 0.3s ease;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feature-card__label {
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  opacity: 0.8;
}

.feature-card__title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.02em;
  margin-bottom: 1rem;
}

.feature-card__description {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.feature-card__list {
  list-style-type: none;
  padding-left: 0;
}

.feature-card__list li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.feature-card__list li::before {
  content: '✅';
  position: absolute;
  left: 0;
}

.feature-card__logos {
  display: flex;
  
  align-items: center;
  margin-top: auto;
}



/* Card-specific styles */
.feature-card:nth-child(1) {
  background: linear-gradient(135deg, #ff6b6b, #feca57);
  color: #fff;
}

.feature-card:nth-child(2) {
  background: linear-gradient(135deg, #54a0ff, #5f27cd);
  color: #fff;
}

.feature-card:nth-child(3) {
  background: linear-gradient(135deg, #ff9ff3, #feca57);
  color: #1d1d1f;
}

.feature-card:nth-child(4) {
  background: linear-gradient(135deg, #48dbfb, #ff6b6b);
  color: #fff;
}

.feature-card:nth-child(5) {
  background: linear-gradient(135deg, #1dd1a1, #10ac84);
  color: #fff;
}

.feature-card:nth-child(6) {
  background: linear-gradient(135deg, #5f27cd, #341f97);
  color: #fff;
}

/* Hover effects */
.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0,0,0,0.2);
}

/* Floating effect */
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.feature-card {
  animation: float 6s ease-in-out infinite;
}

/* Staggered animation for cards */
.feature-card:nth-child(1) { animation-delay: 0s; }
.feature-card:nth-child(2) { animation-delay: 1s; }
.feature-card:nth-child(3) { animation-delay: 2s; }
.feature-card:nth-child(4) { animation-delay: 3s; }
.feature-card:nth-child(5) { animation-delay: 4s; }
.feature-card:nth-child(6) { animation-delay: 5s; }

/* Glowing effect on hover */
.feature-card::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature-card:hover::after {
  opacity: 1;
}

/* Responsive design */
@media (max-width: 1200px) {
  .feature-showcase__title {
    font-size: 4rem;
  }
  
  .feature-card {
    width: 350px;
    height: 550px;
  }
  
  .feature-card__title {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .feature-showcase__title {
    font-size: 3rem;
    text-align: center;
  }
  
  .feature-card {
    width: 300px;
    height: 500px;
  }
  
  .feature-card__title {
    font-size: 2rem;
  }
}

/* Accessibility improvements */
.feature-card {
  outline: none;
}

.feature-card:focus-visible {
  box-shadow: 0 0 0 3px #4299e1;
}

/* Performance optimizations */
@media (prefers-reduced-motion: reduce) {
  .feature-card {
    animation: none;
  }
}

/* Print styles */
@media print {
  .feature-showcase__scroll {
    display: block;
  }
  
  .feature-card {
    page-break-inside: avoid;
    break-inside: avoid;
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
}

/* High contrast mode */
@media (forced-colors: active) {
  .feature-card {
    border: 2px solid CanvasText;
  }
  
  .feature-card__label,
  .feature-card__title {
    color: CanvasText;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .feature-showcase {
    background-color: #1d1d1f;
  }
  
  .feature-showcase__title {
    color: #f5f5f7;
  }
}.feature-card__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: auto;
  }
  
  .feature-card__logo-text {
    font-size: 1.0rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.2);
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 15px;
    transition: all 0.3s ease;
  }
  
  .feature-card__logo-text:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transform: translateY(-2px);
  }
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

  .feature-card__logos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    margin-top: auto;
  }
  
  .feature-card__logo-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .feature-card__logo-text:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .hackerrank {
    color: #39424e;
    background-color: #1ba94c;
  }
  
  .codechef {
    color: #7b5e47;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
  }
  
  .leetcode {
    color: #ffa116;
    background-color: #ffffff;
  }
  
  .codeforces {
    color: #318ce7;
    background-color: #ffffff;
    font-family: 'Arial', sans-serif;
  }
  
  .topcoder {
    background: linear-gradient(90deg, #29abe2, #f79421);
    color: white;
    font-family: 'Arial', sans-serif;
    text-transform: lowercase;
  }