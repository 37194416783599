/* Navigation.module.css */
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;500;600&display=swap');

:root {
  --apple-blue: #2997ff;
  --apple-dark: #1d1d1f;
  --apple-light: #f5f5f7;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  height: 60px; /* Reduced height */
}

.scrolled {
  background-color: rgba(0, 0, 0, 0.95);
}

.nav {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  height: 100%; /* Ensure nav takes full height of navigation */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%; /* Ensure container takes full height of nav */
}

.logo {
  text-decoration: none;
}

.logoText {
  font-size: 1.5rem; /* Slightly reduced font size */
  font-weight: 600;
  letter-spacing: -0.5px;
  background: linear-gradient(90deg, #FF9500, #FFD60A, #32D74B, #0071e3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientShift 8s ease infinite;
}

@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.internI, .indiaI {
  color: #FF9500;
  -webkit-text-fill-color: #FF9500; /* Orange color for 'i' */
}

.internText, .indiaText {
  color: #ffffff;
}

.menuContainer {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menuItem {
  color: #ffffff;
  text-decoration: none;
  padding: 0.3rem 0.8rem; /* Reduced padding */
  transition: all 0.3s ease;
  position: relative;
  font-size: 0.9rem; /* Slightly reduced font size */
  font-weight: 400;
}

.menuItem::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #0071e3;
  transition: all 0.3s ease;
}

.menuItem:hover::after {
  width: 100%;
  left: 0;
}

.applyButton {
  background-color: #0071e3;
  color: #ffffff;
  padding: 0.4rem 1rem; /* Reduced padding */
  border-radius: 15px; /* Slightly reduced border radius */
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 0.9rem; /* Slightly reduced font size */
}

.applyButton:hover {
  background-color: #0077ED;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 113, 227, 0.3);
}

.mobileMenuButton {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.menuIcon, .menuIcon::before, .menuIcon::after {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #ffffff;
  position: relative;
  transition: all 0.3s ease;
}

.menuIcon::before, .menuIcon::after {
  content: '';
  position: absolute;
}

.menuIcon::before {
  top: -8px;
}

.menuIcon::after {
  bottom: -8px;
}

.mobileMenuButton.open .menuIcon {
  background-color: transparent;
}

.mobileMenuButton.open .menuIcon::before {
  transform: rotate(45deg);
  top: 0;
}

.mobileMenuButton.open .menuIcon::after {
  transform: rotate(-45deg);
  bottom: 0;
}

@media (max-width: 768px) {
  .mobileMenuButton {
    display: block;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.95);
    backdrop-filter: saturate(180%) blur(20px);
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .mobileMenuOpen {
    display: flex;
  }

  .menuItem {
    padding: 0.6rem 0;
    font-size: 1rem;
  }

  .menuItem::after {
    display: none;
  }

  .applyButton {
    display: none;
  }

  .mobileMenuOpen .menuItem {
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeInUp 0.3s forwards;
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media (max-width: 480px) {
  .logoText {
    font-size: 1.2rem;
  }

  .container {
    padding: 0 1rem;
  }
}