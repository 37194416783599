@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;600;700&display=swap');

.internshipCTA {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  background: #000;
  color: #f5f5f7;
  padding: 70px 0;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.container {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 22px;
  position: relative;
}

.content {
  text-align: center;
  background: rgba(22, 22, 23, 0.8);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 60px 40px;
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1) inset;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 25px 50px rgba(0, 0, 0, 0.5),
    0 0 0 1px rgba(255, 255, 255, 0.2) inset;
}

.title {
  font-size: 56px;
  line-height: 1.07143;
  font-weight: 700;
  letter-spacing: -0.005em;
  margin-bottom: 20px;
  background: linear-gradient(120deg, #2d98da, #5f27cd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.highlight {
  position: relative;
  display: inline-block;
  background: linear-gradient(120deg, #ff6b6b, #feca57);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(120deg, #ff6b6b, #feca57);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform: scaleX(1);
}



.description {
  font-size: 28px;
  line-height: 1.10722;
  font-weight: 400;
  letter-spacing: .004em;
  color: #a1a1a6;
  margin-bottom: 40px;
}

.priceWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.oldPrice {
  font-size: 24px;
  line-height: 1.33337;
  font-weight: 400;
  letter-spacing: .009em;
  color: #86868b;
  text-decoration: line-through;
  margin-right: 10px;
}

.newPrice {
  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 0;
  background: linear-gradient(120deg, #2d98da, #5f27cd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.savingsTag {
  background: linear-gradient(120deg, #ff6b6b, #feca57);
  color: #000;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 20px;
  margin-left: 10px;
  box-shadow: 0 2px 5px rgba(255, 107, 107, 0.3);
}

.ctaButton {
  display: inline-block;
  background: linear-gradient(120deg, #2d98da, #5f27cd);
  color: #ffffff;
  font-size: 17px;
  line-height: 1.17648;
  font-weight: 600;
  letter-spacing: -0.022em;
  min-width: 28px;
  padding: 12px 24px;
  border-radius: 980px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 
    0 4px 6px rgba(0, 0, 0, 0.2),
    0 1px 3px rgba(0, 0, 0, 0.1);
}

.ctaButton:hover {
  transform: translateY(-2px);
  box-shadow: 
    0 7px 14px rgba(0, 0, 0, 0.3),
    0 2px 4px rgba(0, 0, 0, 0.2);
}

.ctaButton:active {
  transform: translateY(0);
  box-shadow: 
    0 2px 3px rgba(0, 0, 0, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 734px) {
  .internshipCTA {
    padding: 80px 0;
  }

  .content {
    padding: 40px 20px;
  }

  .title {
    font-size: 40px;
    line-height: 1.1;
  }

  .description {
    font-size: 21px;
    line-height: 1.381;
  }

  .newPrice {
    font-size: 32px;
  }

  .ctaButton {
    font-size: 16px;
    padding: 10px 20px;
  }
}