/* AppleFAQ.module.css */
.faqSection {
  background: linear-gradient(to bottom, #000000, #1a1a1a);
  padding: 80px 0;
  color: #ffffff;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 2;
}

.title {
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  background: linear-gradient(90deg, #fff, #a0a0a0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlight {
  color: #2997ff;
  -webkit-text-fill-color: #2997ff;
}

.faqList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faqItem {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  position: relative;
  z-index: 3;
}

.faqItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.faqQuestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  background: none;
  border: none;
  text-align: left;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  z-index: 4;
}

.faqQuestion:hover {
  background: rgba(255, 255, 255, 0.1);
}

.icon {
  font-size: 24px;
  color: #2997ff;
  pointer-events: none; /* Ensure icon doesn't interfere with clicks */
}

.faqAnswer {
  background: rgba(41, 151, 255, 0.1);
  padding: 20px;
  font-size: 16px;
  line-height: 1.6;
  color: #a0a0a0;
  position: relative;
  z-index: 3;
}

@media (max-width: 768px) {
  .title {
    font-size: 36px;
  }

  .faqQuestion {
    font-size: 16px;
    padding: 15px;
  }

  .faqAnswer {
    font-size: 14px;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 28px;
  }

  .faqQuestion {
    font-size: 14px;
    padding: 12px;
  }

  .faqAnswer {
    font-size: 12px;
    padding: 12px;
  }
}

/* Add a cool hover effect to the FAQ items */
.faqItem {
  position: relative;
  overflow: hidden;
}

.faqItem::before {
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  width: 300%;
  height: 300%;
  background: radial-gradient(circle, rgba(41, 151, 255, 0.1) 0%, transparent 70%);
  transition: all 0.5s ease;
}

.faqItem:hover::before {
  top: -50%;
  left: -50%;
}