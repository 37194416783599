.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: #1a1a1a;
    color: #ffffff;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 
      8px 8px 16px #000000,
      -8px -8px 16px #333333;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .popup-content h2 {
    color: #00e5be;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .popup-content p {
    color: #cccccc;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .popup-content button {
    background-color: #00e5be;
    color: #000000;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 
      4px 4px 8px #000000,
      -4px -4px 8px #333333;
  }
  
  .popup-content button:hover {
    background-color: #00c2a1;
    box-shadow: 
      2px 2px 4px #000000,
      -2px -2px 4px #333333;
  }
  
  .popup-content button:active {
    box-shadow: 
      inset 2px 2px 4px #000000,
      inset -2px -2px 4px #333333;
  }