.application-form {
  --primary-color: #00a8ff;
  --secondary-color: #0097e6;
  --background-color: #121212;
  --surface-color: #1e1e1e;
  --text-color: #ffffff;
  --text-color-secondary: #b3b3b3;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --highlight-color: rgba(255, 255, 255, 0.05);

  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}

.application-form .sleek-form-container {
  max-width: 1200px;
  margin: 50px auto;
 
  padding: 40px;
  background-color: var(--surface-color);
  border-radius: 30px;
  box-shadow: 
    20px 20px 60px var(--shadow-color),
    -20px -20px 60px var(--highlight-color);
}

.application-form .sleek-form-header {
  text-align: center;
  margin-bottom: 30px;
}

.application-form .sleek-form-header h1 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.application-form .sleek-form-header p {
  font-size: 1.2rem;
  color: var(--text-color-secondary);
}

.application-form .sleek-form-content {
  display: flex;
  gap: 40px;
}

.application-form .sleek-form-benefits,
.application-form .sleek-form-application {
  flex: 1;
  padding: 30px;
  border-radius: 20px;
  background-color: var(--surface-color);
  box-shadow: 
    inset 5px 5px 10px var(--shadow-color),
    inset -5px -5px 10px var(--highlight-color);
}

.application-form .sleek-form-benefits h2,
.application-form .sleek-form-application h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.application-form .sleek-form-benefit-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 15px;
  background-color: var(--surface-color);
  box-shadow: 
    5px 5px 10px var(--shadow-color),
    -5px -5px 10px var(--highlight-color);
  transition: all 0.3s ease;
}

.application-form .sleek-form-benefit-item:hover {
  transform: translateY(-5px);
  box-shadow: 
    8px 8px 15px var(--shadow-color),
    -8px -8px 15px var(--highlight-color);
}

.application-form .sleek-form-benefit-icon {
  font-size: 2rem;
  color: var(--primary-color);
  margin-right: 20px;
}

.application-form .sleek-form-benefit-item h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: var(--text-color);
}

.application-form .sleek-form-benefit-item p {
  font-size: 0.9rem;
  color: var(--text-color-secondary);
}

.application-form .sleek-form-course-info {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 15px;
  background-color: var(--primary-color);
  color: var(--text-color);
  box-shadow: 
    5px 5px 10px var(--shadow-color),
    -5px -5px 10px var(--highlight-color);
}

.application-form .sleek-form-amount {
  font-size: 2.5rem;
  font-weight: 700;
}

.application-form .sleek-form-details {
  font-size: 1rem;
  opacity: 0.8;
}

.application-form .sleek-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.application-form .sleek-form-group {
  position: relative;
}

.application-form .sleek-form-input,
.sleek-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: black;
  background-color: #f8f8f8;
  transition: border-color 0.3s ease;
}

.sleek-form select {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
}

.sleek-form-input:focus,
.sleek-form select:focus {
  outline: none;
  border-color: #007bff;
}

.application-form .sleek-form-label {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 1rem;
  color: var(--text-color-secondary);
  transition: all 0.3s ease;
  pointer-events: none;
}

.application-form .sleek-form-input:focus + .sleek-form-label,
.application-form .sleek-form-input:not(:placeholder-shown) + .sleek-form-label {
  top: -10px;
  left: 10px;
  font-size: 0.8rem;
  padding: 0 5px;
  background-color: var(--surface-color);
  color: var(--primary-color);
}

.application-form .sleek-form-submit {
  padding: 15px;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-color);
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 
    5px 5px 10px var(--shadow-color),
    -5px -5px 10px var(--highlight-color);
}

.application-form .sleek-form-submit:hover {
  background-color: var(--secondary-color);
  transform: translateY(-3px);
  box-shadow: 
    8px 8px 16px var(--shadow-color),
    -8px -8px 16px var(--highlight-color);
}

.application-form .sleek-form-success {
  text-align: center;
  padding: 30px;
  background-color: var(--primary-color);
  color: var(--text-color);
  border-radius: 15px;
  box-shadow: 
    5px 5px 10px var(--shadow-color),
    -5px -5px 10px var(--highlight-color);
}

.application-form .sleek-form-success-icon {
  font-size: 4rem;
  margin-bottom: 20px;
}

.application-form .sleek-form-error {
  color: #e74c3c;
  text-align: center;
  margin-top: 15px;
}

.application-form .loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.application-form .loading-content {
  padding: 30px;
  border-radius: 20px;
  background-color: var(--surface-color);
  box-shadow: 
    10px 10px 20px var(--shadow-color),
    -10px -10px 20px var(--highlight-color);
  text-align: center;
}

.application-form .loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid var(--surface-color);
  border-top: 5px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.application-form .loading-text {
  font-size: 1.2rem;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.application-form .loading-progress-bar {
  width: 100%;
  height: 10px;
  background-color: var(--surface-color);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 
    inset 3px 3px 6px var(--shadow-color),
    inset -3px -3px 6px var(--highlight-color);
}

.application-form .loading-progress {
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

.application-form .loading-percentage {
  margin-top: 10px;
  font-size: 1rem;
  color: var(--text-color);
}

@media (max-width: 768px) {
  .application-form .sleek-form-content {
    flex-direction: column;
  }
  
  .application-form .sleek-form-container {
    padding: 20px;
  }
  
  .application-form .sleek-form-header h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .application-form .sleek-form-header h1 {
    font-size: 2rem;
  }
  
  .application-form .sleek-form-benefit-item {
    flex-direction: column;
    text-align: center;
  }
  
  .application-form .sleek-form-benefit-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}