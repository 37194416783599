.job-form-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.job-form-header {
  text-align: center;
  margin-bottom: 2rem;
}

.job-form-header h2 {
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.job-form-header p {
  color: #34495e;
  font-size: 1.1rem;
}

.job-submission-form {
  display: grid;
  gap: 1rem;
}

.form-group {
  position: relative;
}

.form-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #3498db;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.8rem 1rem 0.8rem 3rem;
  border: none;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  box-shadow: 0 0 0 2px #3498db;
}

.checkbox-group {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 0.5rem;
}

.btn-submit {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #2980b9;
}

.password-group {
  display: flex;
}

.password-group input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-unlock {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-unlock:hover {
  background-color: #27ae60;
}
/* ... (previous styles remain the same) ... */

.password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-form .form-group {
  width: 100%;
  max-width: 300px;
  margin-bottom: 1rem;
}

.password-form .btn-submit {
  width: 100%;
  max-width: 300px;
}