/* AnnouncementBanner.css */
.announcement-banner-wrapper {
  margin: 90px auto 20px;
  width: 95%;
  max-width: 1200px;
  background-color: #000;
  border-radius: 15px;
  padding: 3px;
  box-shadow: 
    0 0 10px rgba(0, 255, 255, 0.5),
    0 0 20px rgba(255, 0, 255, 0.5);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.announcement-banner-wrapper::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background: linear-gradient(45deg, #00ffff, #ff00ff, #00ffff, #ff00ff);
  background-size: 400% 400%;
  animation: holographicShift 15s ease infinite;
  opacity: 0.3;
  filter: blur(20px);
}

.announcement-banner {
  height: 90px;
  background-color: #0a0a0a;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.announcement-banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, transparent, #00ffff, #ff00ff, transparent);
  animation: scanline 2s linear infinite;
}

.announcement-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 40px;
  position: relative;
  z-index: 2;
}

.announcement-icon {
  font-size: 36px;
  margin-right: 30px;
  color: #fff;
  text-shadow: 
    0 0 5px #00ffff,
    0 0 10px #ff00ff;
  animation: float 3s ease-in-out infinite;
}

.announcement-text {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  text-shadow: 
    2px 2px 0 rgba(0, 0, 0, 0.5),
    -2px -2px 0 rgba(0, 0, 0, 0.5);
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin-right: 30px;
}

.announcement-button {
  padding: 12px 24px;
  background-color: #ff00ff;
  color: #fff;
  text-decoration: none;
  border-radius: 25px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 0, 255, 0.7);
  white-space: nowrap;
}

.announcement-button:hover {
  background-color: #00ffff;
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.7);
}

@media (max-width: 1024px) {
  .announcement-banner {
    height: 120px;
  }

  .announcement-text {
    font-size: 26px;
  }

  .announcement-icon {
    font-size: 32px;
  }

  .announcement-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .announcement-banner {
    height: auto;
  }

  .announcement-content {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }

  .announcement-icon {
    font-size: 36px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .announcement-text {
    font-size: 22px;
    margin-right: 0;
    margin-bottom: 15px;
    line-height: 1.4;
  }

  .announcement-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@keyframes holographicShift {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

@keyframes scanline {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(100%); }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

@media (prefers-reduced-motion: reduce) {
  .announcement-banner-wrapper::before,
  .announcement-banner::after,
  .announcement-icon,
  .announcement-button {
    animation: none;
    transition: none;
  }
}