@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;600;700&display=swap');

.topicDetails {
  background-color: #000;
  color: #fff;
  padding: 5rem 3rem;
  margin: 2rem auto;
  overflow: hidden;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  max-width: 1200px;
  border-radius: 30px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4), 0 0 0 1px rgba(255, 255, 255, 0.1),
              0 0 20px rgba(255, 255, 255, 0.05), 0 0 40px rgba(255, 255, 255, 0.05);
  position: relative;
  background: linear-gradient(to bottom, #000000, #1a1a1a);
}

.topicDetails::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
}

.title {
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 4rem;
  letter-spacing: -0.02em;
}

.flowchartContainer {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}

.flowchart {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  position: relative;
  padding: 2rem;
  border-radius: 20px;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.cppFlowchart, .mernFlowchart {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.flowchart:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(255, 255, 255, 0.2);
}

.flowchartTitle {
  font-size: 2.5rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 2rem;
  text-align: center;
}

.node {
  padding: 1rem 1.5rem;
  border-radius: 12px;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.node:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.main {
  font-size: 1rem;
  background: linear-gradient(135deg, #007aff, #5ac8fa);
  color: #fff;
}

.secondary {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.row {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  position: relative;
}

.connector {
  position: absolute;
  bottom: -2rem;
  left: 50%;
  width: 2px;
  height: 2rem;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

.row .connector {
  bottom: -1.5rem;
}

@media (max-width: 1024px) {
  .flowchartContainer {
    flex-direction: column;
  }

  .flowchart {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .topicDetails {
    padding: 3rem 1.5rem;
    border-radius: 20px;
    margin: 1rem auto;
    max-width: 90%;
  }

  .title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .flowchartContainer {
    gap: 2rem;
  }

  .flowchart {
    padding: 1.5rem;
    gap: 1.5rem;
  }

  .flowchartTitle {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  
  .node {
    padding: 0.8rem 1rem;
    font-size: 0.9rem;
    max-width: none;
    width: 100%;
    border-radius: 8px;
  }
  
  .row {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .connector {
    height: 1.5rem;
    width: 1px;
  }

  .row .connector {
    height: 1.5rem;
    bottom: -1.5rem;
  }

  .main {
    font-size: 1rem;
    padding: 0.8rem 1rem;
  }

  .secondary {
    font-size: 0.9rem;
    padding: 0.7rem 0.9rem;
  }
}

@media (max-width: 480px) {
  .topicDetails {
    padding: 2rem 1rem;
    border-radius: 15px;
  }

  .title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .flowchartTitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .node {
    padding: 0.7rem 0.9rem;
    font-size: 0.8rem;
    border-radius: 6px;
  }

  .main {
    font-size: 0.9rem;
    padding: 0.7rem 0.9rem;
  }

  .secondary {
    font-size: 0.8rem;
    padding: 0.6rem 0.8rem;
  }

  .connector {
    height: 1.2rem;
  }

  .row .connector {
    height: 1.2rem;
    bottom: -1.2rem;
  }
}

html {
  scroll-behavior: smooth;
}