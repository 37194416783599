.refund-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
}

.refund-policy-container h1 {
  font-size: 2.5rem;
  color: #4a90e2;
  margin-bottom: 1.5rem;
  text-align: center;
}

.refund-policy-container h2 {
  font-size: 1.8rem;
  color: #333;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #4a90e2;
  padding-bottom: 0.5rem;
}

.refund-policy-container p {
  margin-bottom: 1rem;
}

.refund-policy-container ul, .refund-policy-container ol {
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.refund-policy-container li {
  margin-bottom: 0.5rem;
}

.refund-policy-container a {
  color: #4a90e2;
  text-decoration: none;
}

.refund-policy-container a:hover {
  text-decoration: underline;
}
