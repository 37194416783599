@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;500;600;700&display=swap');

.intern-india-footer {
  --primary-color: #1E90FF; /* Dodger Blue */
  --text-color: #FFFFFF;
  --text-color-secondary: #CCCCCC;
  --background-color: #000000;
  --shadow-dark: #1A1A1A;
  --shadow-light: #2A2A2A;

  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 12vw 2rem 2rem; /* Use viewport width for top padding */
  position: relative;
  overflow: hidden;
   /* Negative margin to pull content up */
}

.intern-india-footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* Use viewport width for height */
  background-color: var(--background-color);
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  transform: translateY(-50%);
  box-shadow: 
    inset 5px 5px 10px var(--shadow-dark),
    inset -5px -5px 10px var(--shadow-light);
}

.intern-india-footer .footer-content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.intern-india-footer .footer-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 20px;
  background: var(--background-color);
  box-shadow: 
    8px 8px 16px var(--shadow-dark),
    -8px -8px 16px var(--shadow-light);
}

.intern-india-footer .footer-logo {
  flex-basis: 100%;
  margin-bottom: 3rem;
  text-align: center;
}

.intern-india-footer .logo-link {
  text-decoration: none;
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 20px;
  background: var(--background-color);
  box-shadow: 
    8px 8px 16px var(--shadow-dark),
    -8px -8px 16px var(--shadow-light);
  transition: all 0.3s ease;
}

.intern-india-footer .logo-link:hover {
  box-shadow: 
    12px 12px 20px var(--shadow-dark),
    -12px -12px 20px var(--shadow-light);
}

.intern-india-footer .logo-text {
  font-size: 3.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #FF9500, #FFD60A, #32D74B, #0071e3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientShift 8s ease infinite;
}

.intern-india-footer .logo-i,
.intern-india-footer .logo-i2 {
  display: inline-block;
  padding: 0 0.2rem;
  border-radius: 8px;
  background: var(--background-color);
  color: var(--primary-color);
  background: linear-gradient(90deg, #FF9500, #FFD60A, #32D74B, #0071e3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientShift 8s ease infinite;
  
}

.intern-india-footer .logo-i {
  font-size: 5rem;
  color:orange;
}

.intern-india-footer .logo-i2 {
  font-size: 5rem;
  color:green;
}

.intern-india-footer .footer-address,
.intern-india-footer .footer-contact {
  flex-basis: calc(50% - 1rem);
  font-size: 0.9rem;
  line-height: 1.5;
}

.intern-india-footer .footer-contact a {
  color: var(--text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background: var(--background-color);
  box-shadow: 
    3px 3px 6px var(--shadow-dark),
    -3px -3px 6px var(--shadow-light);
  transition: all 0.3s ease;
}

.intern-india-footer .footer-contact a:hover {
  box-shadow: 
    inset 3px 3px 6px var(--shadow-dark),
    inset -3px -3px 6px var(--shadow-light);
}

.intern-india-footer .footer-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.intern-india-footer .link-column {
  flex-basis: calc(25% - 1rem);
  margin-bottom: 2rem;
  padding: 1rem;
  border-radius: 15px;
  background: var(--background-color);
  box-shadow: 
    5px 5px 10px var(--shadow-dark),
    -5px -5px 10px var(--shadow-light);
}

.intern-india-footer .link-column h3 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.intern-india-footer .link-column a {
  display: block;
  color: var(--text-color-secondary);
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  padding: 0.5rem;
  border-radius: 8px;
}

.intern-india-footer .link-column a:hover {
  color: var(--primary-color);
  box-shadow: 
    inset 2px 2px 4px var(--shadow-dark),
    inset -2px -2px 4px var(--shadow-light);
}

.intern-india-footer .footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.intern-india-footer .footer-bottom p {
  font-size: 0.8rem;
  color: var(--text-color-secondary);
}

.intern-india-footer .social-media {
  display: flex;
}

.intern-india-footer .social-media a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 1rem;
  border-radius: 50%;
  background: var(--background-color);
  color: var(--primary-color);
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 
    4px 4px 8px var(--shadow-dark),
    -4px -4px 8px var(--shadow-light);
}

.intern-india-footer .social-media a:hover {
  transform: translateY(-3px);
  box-shadow: 
    6px 6px 12px var(--shadow-dark),
    -6px -6px 12px var(--shadow-light);
}

@media (max-width: 1200px) {
  .intern-india-footer {
    padding-top: 14vw;
    margin-top: -12vw;
  }
  
  .intern-india-footer::before {
    height: 14vw;
  }
  
  .intern-india-footer .logo-text {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .intern-india-footer {
    padding-top: 16vw;
    
  }
  
  .intern-india-footer::before {
    height: 16vw;
  }
  
  .intern-india-footer .logo-text {
    font-size: 2.5rem;
  }
  
  .intern-india-footer .footer-main,
  .intern-india-footer .footer-links {
    flex-direction: column;
  }
  
  .intern-india-footer .footer-address,
  .intern-india-footer .footer-contact,
  .intern-india-footer .link-column {
    flex-basis: 100%;
    margin-bottom: 2rem;
  }
}

@media (max-width: 480px) {
  .intern-india-footer {
    padding-top: 20vw;
    margin-top: -18vw;
  }
  
  .intern-india-footer::before {
    height: 20vw;
  }
  
  .intern-india-footer .logo-text {
    font-size: 2rem;
  }
  
  .intern-india-footer .footer-bottom {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .intern-india-footer .social-media {
    margin-top: 1rem;
  }
}