.admin-panel {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Roboto', Arial, sans-serif;
  color: #333;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.admin-panel__title {
  text-align: center;
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.admin-panel__content {
  display: flex;
  gap: 2rem;
}

.admin-panel__job-list,
.admin-panel__job-form {
  flex: 1;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-panel__search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  background-color: #f1f3f5;
  border-radius: 4px;
  padding: 0.5rem;
}

.admin-panel__search-icon {
  color: #7f8c8d;
  margin-right: 0.5rem;
}

.admin-panel__search-input {
  border: none;
  background-color: transparent;
  font-size: 1rem;
  width: 100%;
  outline: none;
}

.admin-panel__table-container {
  overflow-x: auto;
}

.admin-panel__table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}

.admin-panel__table th,
.admin-panel__table td {
  padding: 1rem;
  text-align: left;
}

.admin-panel__table th {
  background-color: #3498db;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.admin-panel__table tr {
  background-color: #f8f9fa;
  transition: background-color 0.3s ease;
}

.admin-panel__table tr:hover {
  background-color: #e9ecef;
}

.admin-panel__table tr.selected {
  background-color: #d6eaf8;
}

.admin-panel__btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.1s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.admin-panel__btn:hover {
  transform: translateY(-1px);
}

.admin-panel__btn--edit {
  background-color: #3498db;
  color: #fff;
}

.admin-panel__btn--edit:hover {
  background-color: #2980b9;
}

.admin-panel__btn--delete {
  background-color: #e74c3c;
  color: #fff;
}

.admin-panel__btn--delete:hover {
  background-color: #c0392b;
}

.admin-panel__form-title {
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.admin-panel__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.admin-panel__form-group {
  display: flex;
  flex-direction: column;
}

.admin-panel__form-group label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #34495e;
}

.admin-panel__input,
.admin-panel__select {
  padding: 0.75rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.admin-panel__input:focus,
.admin-panel__select:focus {
  border-color: #3498db;
  outline: none;
}

.admin-panel__form-group--checkbox {
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.admin-panel__checkbox {
  width: 1.25rem;
  height: 1.25rem;
}

.admin-panel__form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.admin-panel__btn--submit {
  background-color: #2ecc71;
  color: #fff;
}

.admin-panel__btn--submit:hover {
  background-color: #27ae60;
}

.admin-panel__btn--cancel {
  background-color: #95a5a6;
  color: #fff;
}

.admin-panel__btn--cancel:hover {
  background-color: #7f8c8d;
}

@media (max-width: 768px) {
  .admin-panel__content {
    flex-direction: column;
  }

  .admin-panel__job-list,
  .admin-panel__job-form {
    width: 100%;
  }
}