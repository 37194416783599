@keyframes floatAnimation {
  0%, 100% { transform: translateY(0) rotate(0deg); }
  25% { transform: translateY(-10px) rotate(1deg); }
  75% { transform: translateY(10px) rotate(-1deg); }
}

@keyframes glowPulse {
  0%, 100% { box-shadow: 0 0 20px rgba(41, 151, 255, 0.5); }
  50% { box-shadow: 0 0 40px rgba(41, 151, 255, 0.8); }
}

.apple-learn-section {
  background: linear-gradient(135deg, #000000, #000000);
  color: #ffffff;
  padding: 80px 0;
  overflow: hidden;
  border-radius: 30px;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
  position: relative;
  transition: all 0.3s ease;
}



.apple-learn-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 70%);
  animation: rotate 20s linear infinite;
  z-index: 1;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.apple-learn-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60px;
  padding: 0 20px;
  position: relative;
  z-index: 2;
}

.apple-learn-content {
  flex: 1;
  animation: fadeInUp 1s ease-out;
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.apple-learn-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.apple-learn-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #ff6b6b, #feca57, #48dbfb, #ff9ff3);
  border-radius: 3px;
  animation: rainbowMove 5s linear infinite;
}

@keyframes rainbowMove {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.apple-highlight {
  color: #48dbfb;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #48dbfb, #ff9ff3);
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
  display: inline-block;
  padding: 0 10px;
  transition: all 0.3s ease;
}

.apple-highlight::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(72, 219, 251, 0.1);
  transform: skew(-10deg);
  z-index: -1;
  transition: all 0.3s ease;
}

.apple-highlight:hover {
  transform: scale(1.05);
}

.apple-highlight:hover::before {
  transform: skew(-10deg) translateX(5px);
  background: rgba(72, 219, 251, 0.2);
}

.apple-learn-subtitle {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #feca57;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.5px;
}

.apple-learn-description {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 32px;
  color: #a0a0a0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.3px;
}

.apple-learn-button {
  background: linear-gradient(45deg, #2997ff, #1a7ad9);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(41, 151, 255, 0.3);
  position: relative;
  overflow: hidden;
}

.apple-learn-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 70%);
  transform: scale(0);
  transition: transform 0.5s ease-out;
}

.apple-learn-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(41, 151, 255, 0.4);
}

.apple-learn-button:hover::before {
  transform: scale(1);
}

.apple-learn-image-container {
  flex: 1;
  position: relative;
  perspective: 1000px;
}

.apple-learn-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease;
  transform-style: preserve-3d;
  animation: floatAnimation 6s ease-in-out infinite;
}

.apple-learn-image:hover {
  transform: scale(1.05) rotateY(10deg);
  box-shadow: 0 40px 80px rgba(0, 0, 0, 0.6);
}

@media (max-width: 1024px) {
  .apple-learn-container {
    flex-direction: column;
    text-align: center;
  }

  .apple-learn-title {
    font-size: 36px;
  }

  .apple-learn-subtitle {
    font-size: 20px;
  }

  .apple-learn-description {
    font-size: 16px;
  }

  .apple-learn-image {
    margin-top: 40px;
  }
}