/* PrivacyPolicy.css */

.privacy-policy-container {
    max-width: 800px;
    font-weight: 600;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .privacy-policy-container h1 {
    font-size: 2.5em;
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container .last-updated {
    font-style: italic;
    color: #7f8c8d;
    margin-bottom: 30px;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.8em;
    color: #2980b9;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .privacy-policy-container h3 {
    font-size: 1.4em;
    color: #16a085;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container h4 {
    font-size: 1.2em;
    color: #27ae60;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container p {
    margin-bottom: 15px;
  }
  
  .privacy-policy-container ul {
    margin-bottom: 15px;
    padding-left: 30px;
  }
  
  .privacy-policy-container li {
    margin-bottom: 5px;
  }
  
  .privacy-policy-container a {
    color: #3498db;
    text-decoration: none;
  }
  
  .privacy-policy-container a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 15px;
    }
  
    .privacy-policy-container h1 {
      font-size: 2em;
    }
  
    .privacy-policy-container h2 {
      font-size: 1.6em;
    }
  
    .privacy-policy-container h3 {
      font-size: 1.3em;
    }
  
    .privacy-policy-container h4 {
      font-size: 1.1em;
    }
  }