/* Include all styles from CallbackRequest.css first */
@import url('CallbackRequest.css');

/* Additional styles for enhanced features */

.form-group input.error {
  border-color: #ff4d4d;
}

.error-message {
  color: #ff4d4d;
  font-size: 12px;
  margin-top: 5px;
  display: block;
  font-family: 'Poppins', sans-serif;
}


/* Confetti Animation */
.confetti-container {
    
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1002;
}

.confetti {
    
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #f0f0f0;
  animation: confetti-fall 3s linear infinite;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&display=swap');

:root {
  --primary-color: #00e5be;
  --primary-light: #33eacc;
  --primary-dark: #00b296;
  --bg-color: #000000;
  --bg-color-light: #1a1a1a;
  --text-color: #ffffff;
  --text-color-secondary: #cccccc;
  --neumorphic-shadow-light: #1a1a1a;
  --neumorphic-shadow-dark: #000000;
}

.neumorphic {
  background: var(--bg-color);
  box-shadow: 
    5px 5px 10px var(--neumorphic-shadow-dark), 
    -5px -5px 10px var(--neumorphic-shadow-light);
  border: 2px solid var(--primary-color);
  border-radius: 15px;
  transition: all 0.3s ease;
}

.neumorphic:hover {
  box-shadow: 
    3px 3px 6px var(--neumorphic-shadow-dark), 
    -3px -3px 6px var(--neumorphic-shadow-light);
}

.neumorphic:active {
  box-shadow: 
    inset 3px 3px 6px var(--neumorphic-shadow-dark), 
    inset -3px -3px 6px var(--neumorphic-shadow-light);
}

.callback-request-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 50px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 4px solid rgba(3, 3, 202, 0.584);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
}

.callback-icon {
  font-size: 20px;
  margin-right: 10px;
  color: var(--primary-color);
}

.callback-text {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.callback-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  animation: fadeIn 0.3s ease-out;
}

.callback-popup {
  background-color: var(--bg-color);
  border-radius: 20px;
  padding: 30px;
  width: 90%;
  max-width: 400px;
  position: relative;
  animation: slideUp 0.5s ease-out;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 28px;
  color: var(--text-color);
  background: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.callback-form h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color-secondary);
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px 15px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  color: #000000;
  background-color: var(--bg-color-light);

  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 229, 190, 0.3);
}

.submit-button {
  width: 100%;
  padding: 12px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: var(--bg-color);
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: var(--primary-light);
  transform: translateY(-2px);
}

.submit-button:active {
  background-color: var(--primary-dark);
  transform: translateY(0);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Media query for tablets */
@media (max-width: 768px) {
  .callback-request-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .callback-icon {
    font-size: 18px;
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .callback-request-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 0;
    background-color: var(--primary-color);
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .callback-icon {
    font-size: 28px;
    margin-right: 0;
    color: var(--bg-color);
  }

  .callback-text {
    display: none;
  }
}