/* AppleStyleFeatures.css */
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;500;600;700&display=swap');



.apple-style-features {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  
  color: var(--apple-light-gray);
  padding: 40px 10px; /* Reduced side padding */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  --apple-blue: #0071e3;
  --apple-light-blue: #2997ff;
  --apple-dark: #1d1d1f;
  --apple-gray: #86868b;
  --apple-light-gray: #f5f5f7;
  --transition-smooth: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.apple-style-features::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  
  animation: rotate 20s linear infinite;
  z-index: 1;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.apple-content-container {
  max-width: 100%; /* Allow full width on mobile */
  width: 100%;
  position: relative;
  z-index: 2;
  background: rgb(0, 0, 0);
  border-radius: 20px;
  box-shadow: 0 0 20px rgb(0, 0, 0);
  backdrop-filter: blur(10px);
  padding: 20px; /* Reduce padding */
  transform: translateZ(0);
  margin: 0 auto;
}

.apple-feature-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 40px;
  padding: 0 10px; /* Reduce side padding */
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.apple-feature-button {
  flex: 0 1 auto;
  min-width: auto;
  max-width: none;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--apple-light-gray);
  font-size: 14px; /* Increase font size slightly */
  font-weight: 500;
  padding: 8px 16px; /* Adjust padding */
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  text-align: center;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.apple-feature-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  transform: translateX(-100%);
  transition: 0.6s;
}

.apple-feature-button:hover {
  background: rgba(255, 255, 255, 0.12);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.apple-feature-button:hover::before {
  transform: translateX(100%);
}

.apple-feature-button.active {
  background-color: var(--apple-blue);
  border-color: var(--apple-blue);
  color: #fff;
  box-shadow: 0 4px 15px rgba(0, 113, 227, 0.3);
}

.apple-feature-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 113, 227, 0.3);
}

@media (max-width: 768px) {
  .apple-feature-menu {
    flex-direction: column;
    align-items: stretch;
  }

  .apple-feature-button {
    width: 100%;
    font-size: 14px;
    padding: 12px 20px;
  }
}

.apple-feature-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.5s forwards 0.2s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.apple-feature-content {
  flex: 1;
}

.apple-feature-title {
  font-size: clamp(24px, 4vw, 40px);
  font-weight: 700;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.apple-feature-description {
  font-size: clamp(16px, 2vw, 18px);
  line-height: 1.6;
  color: var(--apple-gray);
  margin-bottom: 20px;
}

.apple-feature-highlights {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.apple-feature-highlights li {
  font-size: clamp(14px, 1.8vw, 16px);
  color: var(--apple-light-gray);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.apple-feature-highlights li::before {
  content: '•';
  color: var(--apple-blue);
  font-size: 1.5em;
  margin-right: 10px;
}

.apple-learn-more {
  color: var(--apple-light-blue);
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: var(--transition-smooth);
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
}

.apple-learn-more::after {
  content: '→';
  margin-left: 8px;
  transition: var(--transition-smooth);
}

.apple-learn-more:hover::after {
  transform: translateX(5px);
}

.apple-feature-image {
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.apple-feature-image img {
  max-width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
  transition: var(--transition-smooth);
  animation: floatPhone 6s ease-in-out infinite;
  filter: drop-shadow(0 20px 30px rgba(0, 0, 0, 0.5));
  will-change: transform;
}

@keyframes floatPhone {
  0%, 100% {
    transform: 
      translateY(0) 
      translateX(0) 
      rotateX(0deg) 
      rotateY(-5deg) 
      rotateZ(0deg);
  }
  25% {
    transform: 
      translateY(-20px) 
      translateX(15px) 
      rotateX(3deg) 
      rotateY(-3deg) 
      rotateZ(2deg);
  }
  50% {
    transform: 
      translateY(-35px) 
      translateX(-10px) 
      rotateX(-2deg) 
      rotateY(4deg) 
      rotateZ(-2deg);
  }
  75% {
    transform: 
      translateY(-15px) 
      translateX(20px) 
      rotateX(4deg) 
      rotateY(-5deg) 
      rotateZ(3deg);
  }
}

.apple-feature-image::after {
  content: '';
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  height: 20px;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 70%);
  filter: blur(10px);
  opacity: 0.6;
  animation: shadowPulse 6s ease-in-out infinite;
}

@keyframes shadowPulse {
  0%, 100% { opacity: 0.6; transform: translateX(-50%) scale(1); }
  50% { opacity: 0.8; transform: translateX(-50%) scale(1.2); }
}

/* Hover effect */
.apple-feature-image:hover img {
  animation-play-state: paused;
  transform: 
    translateY(-20px) 
    translateX(10px) 
    rotateX(3deg) 
    rotateY(-3deg) 
    rotateZ(2deg) 
    scale(1.1);
  transition: transform 0.3s ease-out;
}

/* Responsive styles */
@media (min-width: 768px) {
  .apple-style-features {
    padding: 60px 20px; /* Adjust padding for larger screens */
  }

  .apple-feature-button {
    font-size: 18px;
    padding: 15px 30px;
  }

  .apple-feature-content-wrapper {
    flex-direction: row;
    align-items: center;
  }

  .apple-feature-content {
    flex: 0 0 50%;
    padding-right: 40px;
  }

  .apple-feature-image {
    flex: 0 0 50%;
  }
}

@media (min-width: 1024px) {
  .apple-feature-menu {
    gap: 20px;
  }

  .apple-feature-button {
    font-size: 20px;
    padding: 18px 36px;
  }

  .apple-feature-content-wrapper {
    gap: 60px;
  }

  .apple-feature-image img {
    max-height: 600px;
  }
}

/* Animations for content */
.apple-feature-title,
.apple-feature-description,
.apple-learn-more {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

.apple-feature-description {
  animation-delay: 0.1s;
}

.apple-learn-more {
  animation-delay: 0.2s;
}

/* Animation for highlights */
.apple-feature-highlights li {
  opacity: 0;
  transform: translateX(-20px);
  animation: fadeInRight 0.5s forwards;
}

.apple-feature-highlights li:nth-child(1) { animation-delay: 0.3s; }
.apple-feature-highlights li:nth-child(2) { animation-delay: 0.4s; }
.apple-feature-highlights li:nth-child(3) { animation-delay: 0.5s; }

@keyframes fadeInRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .apple-feature-image img,
  .apple-feature-image::after,
  .apple-feature-content-wrapper,
  .apple-feature-title,
  .apple-feature-description,
  .apple-learn-more,
  .apple-feature-highlights li {
    animation: none;
    transition: none;
  }
}

/* New media queries for mobile */
@media (max-width: 767px) {
  .apple-style-features {
    padding: 30px 0px 0px 0px; /* Further reduce padding on very small screens */
  }

  .apple-content-container {
    border-radius: 10px; /* Reduce border radius */
  }

  .apple-feature-content-wrapper {
    gap: 20px; /* Reduce gap */
  }

  .apple-feature-title {
    font-size: clamp(20px, 5vw, 28px); /* Adjust font size */
  }

  .apple-feature-description {
    font-size: clamp(14px, 3vw, 16px); /* Adjust font size */
  }

  .apple-feature-highlights li {
    font-size: clamp(12px, 2.5vw, 14px); /* Adjust font size */
  }

  .apple-learn-more {
    font-size: 16px; /* Reduce font size */
  }

  .apple-feature-image img {
    max-height: 300px; /* Reduce max height of images */
  }
}