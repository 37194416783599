/* TermsAndConditions.css */

.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .terms-container h1 {
    font-size: 2.5em;
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .terms-container h2 {
    font-size: 1.8em;
    color: #2980b9;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .terms-container p {
    margin-bottom: 15px;
  }
  
  .terms-container ul {
    margin-bottom: 15px;
    padding-left: 30px;
  }
  
  .terms-container li {
    margin-bottom: 5px;
  }
  
  .terms-container a {
    color: #3498db;
    text-decoration: none;
  }
  
  .terms-container a:hover {
    text-decoration: underline;
  }
  
  .terms-container section {
    margin-bottom: 30px;
  }
  
  @media (max-width: 768px) {
    .terms-container {
      padding: 15px;
    }
  
    .terms-container h1 {
      font-size: 2em;
    }
  
    .terms-container h2 {
      font-size: 1.6em;
    }
  }